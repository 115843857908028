/*
 * Globals
 */
/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-default,
.btn-default:hover,
.btn-default:focus {
  color: #fff;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #333;
  border: 1px solid #02BF02;
}


/*
 * Base structure
 */

html,
body {
  height: 100%;
	font-family: 'Coda', cursive;
	color: #02BF02;
}
body {
  text-align: center;
  text-shadow: 0 1px 3px rgba(0,0,0,.5);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.welcome-row {
  margin: 2rem auto;
}

.inner {
  padding: 0 16rem;
}

/* Region table */
.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #444;
}
.table-striped th {
  color: #02BF02;
}

/* Map */
.map { 
  width: 100%;
  height: 60vh ;
  margin-bottom: 1em;
}

.region-overlay {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: rgba(0,0,0,.33);
}

.region-overlay > a {
  color: #01fe03;
  font-size: 1.2rem;
  text-decoration: none;
}

.region-overlay > a:hover {
  text-decoration: underline;
}